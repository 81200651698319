
select{
    text-align-last: center;
}

.blend-right{
    border-top-right-radius: 8%;
    border-bottom-right-radius: 8%;
}

.blend-left{
    border-top-left-radius: 8%;
    border-bottom-left-radius: 8%;
}

.ip-form{
    margin-bottom: 15px;
    text-align: left;
    padding-left: 0%;
}

.nopadding{
    padding-left: 0% !important;
    padding-right: 0% !important;
}

.row-container{
    display: flex;
    flex-direction: row;
}
.space-around{
    justify-content: space-around;
}
.space-between{
    justify-content: space-between;
}

.border-succes{
    border-color: red;
  }